import { Box, Button, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { VaultDocument } from '@/types/vault';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import MuiPageWrapper from '@/@mui/MuiPageWrapper';
import { useUpdateCategoryOfDocument } from '@/api/endpoints/document/useUpdateCategoryOfDocument';
import { useDeleteVaultDocument } from '@/api/endpoints/vault/useDeleteVaultDocument';
import { useGetVaultDocuments } from '@/api/endpoints/vault/useGetVaultDocuments';
import { useUploadVaultDocument } from '@/api/endpoints/vault/useUploadVaultDocument';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { UploadCloud01Icon } from '@/assets/icons/UploadCloud01Icon';
import { useAuth } from '@/context/AuthProvider';
import { DocumentStatusProvider } from '@/context/DocumentStatusProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatFullDate } from '@/utils/date';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import DocumentCategoryCell from '@/components/document/DocumentCategoryCell';
import DocumentDeleteModal from '@/components/document/DocumentDeleteModal';
import DocumentUploadButton, {
  API as DocumentUploadButtonAPI,
} from '@/components/document/DocumentUploadButton';
import DocumentViewModal from '@/components/document/DocumentViewModal';
import VaultDocumentTagModal from '@/components/vault/VaultDocumentTagModal';
import VaultDocumentTags from '@/components/vault/VaultDocumentTags';

const VaultPage: React.FC = () => {
  const { session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const { setOnboardingProgress } = useOnboardingProgress();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const history = useHistory();
  const pendingCategoryIdRef = useRef<number | null>(null);
  const dialogOpenedRef = useRef<boolean>(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'created_at', sort: 'desc' },
  ]);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<VaultDocument | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<VaultDocument | null>(null);
  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(null);
  const documentUploadButtonRef = useRef<DocumentUploadButtonAPI>(null);

  const {
    data: documents = [],
    isLoading,
    refetch,
  } = useGetVaultDocuments(
    {
      property_location_id: selectedPropertyLocation?.id,
    },
    !!session?.access_token
  );
  const deleteDocumentMutation = useDeleteVaultDocument({
    onSuccess: () => {
      refetch();
    },
  });

  const updateCategoryOfDocumentMutation = useUpdateCategoryOfDocument({
    onSuccess: document => {
      refetch();

      if (document.category_id === 2) {
        setOnboardingProgress({
          hasLeaseDocument: true,
        });
      }
    },
  });

  const uploadDocumentMutation = useUploadVaultDocument({
    onSuccess: document => {
      if (pendingCategoryIdRef.current) {
        updateCategoryOfDocumentMutation.mutate({
          categoryId: pendingCategoryIdRef.current,
          id: document.id,
        });

        pendingCategoryIdRef.current = null;
      }
    },
  });

  const handleSortModelChange = useCallback((model: GridSortModel) => {
    setSortModel(model);
  }, []);

  const handleUploadClick = useCallback(() => {
    documentUploadButtonRef.current?.click();
  }, []);

  const handleFileSelect = useCallback(
    (file: File) => {
      const searchParams = new URLSearchParams(location.search);
      const defaultCategoryId = searchParams.get('defaultCategoryId');

      // Store category ID for later use after successful upload
      if (defaultCategoryId) {
        pendingCategoryIdRef.current = Number(defaultCategoryId);
        enqueueSnackbar('Document will be categorized as a lease agreement', { variant: 'info' });
      }

      uploadDocumentMutation.mutate({
        file,
        tags: selectedPropertyLocation?.id
          ? {
            property_location_ids: [selectedPropertyLocation.id],
            building_ids: [],
            unit_ids: [],
            tenant_ids: [],
            property_manager_ids: [],
          }
          : undefined,
      });
    },
    [uploadDocumentMutation, selectedPropertyLocation, location.search]
  );

  // Check for openDocumentUpload query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('openDocumentUpload') === 'true' && !dialogOpenedRef.current) {
      // Mark that we've opened the dialog for this session
      dialogOpenedRef.current = true;

      // Store defaultCategoryId if present
      const defaultCategoryId = searchParams.get('defaultCategoryId');
      if (defaultCategoryId) {
        pendingCategoryIdRef.current = Number(defaultCategoryId);
      }

      // Clear URL parameters immediately
      const newUrl = new URL(window.location.href);
      newUrl.search = '';
      history.replace(newUrl.pathname);

      // Open the upload dialog
      handleUploadClick();
    }
  }, [location.search, handleUploadClick, history]);

  const handleTagDocument = useCallback((document: VaultDocument) => {
    setSelectedDocument(document);
    setIsTagModalOpen(true);
  }, []);

  const handleTagModalClose = useCallback(() => {
    setIsTagModalOpen(false);
    setSelectedDocument(null);
  }, []);

  const handleTagModalSuccess = useCallback(() => {
    setIsTagModalOpen(false);
    setSelectedDocument(null);
    refetch();
  }, [refetch]);

  const handlePreviewDocument = useCallback((document: VaultDocument) => {
    setPreviewDocumentId(document.id);
  }, []);

  const handleClosePreview = useCallback(() => {
    setPreviewDocumentId(null);
  }, []);

  const handleDeleteModalClose = useCallback(() => {
    setIsDeleteModalOpen(false);
    setDocumentToDelete(null);
  }, []);

  const handleDocumentDeleted = useCallback(() => {
    if (documentToDelete) {
      setIsDeleteModalOpen(false);
      setDocumentToDelete(null);
    }
  }, [documentToDelete]);

  const handleDeleteClick = useCallback((document: VaultDocument) => {
    setDocumentToDelete(document);
    setIsDeleteModalOpen(true);
  }, []);

  const VaultNoRowsOverlay = useCallback(
    () => (
      <NoRowsOverlay
        entityName="Document"
        addEntityButtonIcon={<UploadCloud01Icon />}
        addEntityButtonText="Upload Document"
        isLoading={uploadDocumentMutation.isPending}
        onAddEntityClick={handleUploadClick}
      />
    ),
    [handleUploadClick, uploadDocumentMutation.isPending]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'original_name',
        headerName: 'Document Name',
        flex: isMobile ? 2 : 1,
        minWidth: 100,
        maxWidth: isMobile ? 200 : 300,
        renderCell: (params: GridRenderCellParams<VaultDocument>) => (
          <Typography
            variant="body2"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
                color: 'primary.main',
              },
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: isMobile ? '0.8125rem' : 'inherit',
              py: isMobile ? 0.5 : 0,
            }}
            onClick={() => handlePreviewDocument(params.row)}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'category',
        headerName: 'Document Category',
        width: isMobile ? 130 : 200,
        hide: isMobile && isTablet,
        renderCell: (params: GridRenderCellParams<VaultDocument>) => (
          <DocumentCategoryCell document={params.row} />
        ),
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 100,
        flex: 0.8,
        align: 'center',
        headerAlign: 'center',
        hide: isMobile,
        renderCell: (params: GridRenderCellParams<VaultDocument>) => (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <VaultDocumentTags document={params.row} />
          </Box>
        ),
      },
      {
        field: 'created_at',
        headerName: 'Date Added',
        width: isMobile ? 100 : 180,
        hide: isMobile && !isTablet,
        renderCell: (params: GridRenderCellParams<VaultDocument>) => (
          <Typography
            variant="body2"
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: isMobile ? '0.8125rem' : 'inherit',
            }}
          >
            {formatFullDate(new Date(params.value as string))}
          </Typography>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: isMobile ? 80 : 120,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<VaultDocument>) => (
          <RowActionsMenu
            items={[
              {
                label: 'Edit Tags',
                onClick: () => handleTagDocument(params.row),
                isLoading: false,
              },
              {
                label: 'Delete',
                onClick: () => handleDeleteClick(params.row),
                isLoading:
                  deleteDocumentMutation.isPending && documentToDelete?.id === params.row.id,
              },
            ]}
          />
        ),
      },
    ],
    [
      handleTagDocument,
      handlePreviewDocument,
      handleDeleteClick,
      deleteDocumentMutation.isPending,
      documentToDelete,
      isMobile,
      isTablet,
    ]
  );

  return (
    <MuiPageWrapper>
      <DocumentStatusProvider>
        <Stack direction="column" spacing={2}>
          <DashboardHeader hasInnerHtml title="Documents: <em>Active</em>" />

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            spacing={2}
            mb={2}
          >
            <Typography
              variant="dataGridBody"
              sx={{
                fontSize: { xs: '0.875rem', sm: 'inherit' },
                mb: { xs: 1, sm: 0 },
              }}
            >
              View and manage your active documents.
            </Typography>
            <Button
              variant="contained"
              startIcon={<PlusIcon />}
              onClick={handleUploadClick}
              disabled={uploadDocumentMutation.isPending}
              fullWidth={isMobile}
              size={isMobile ? 'medium' : 'large'}
              sx={{
                alignSelf: isMobile ? 'stretch' : 'auto',
                py: isMobile ? 1 : 1.5,
                borderRadius: 1.5,
              }}
            >
              Upload Document
            </Button>
          </Stack>

          <DocumentUploadButton
            ref={documentUploadButtonRef}
            isFileUploading={uploadDocumentMutation.isPending}
            onFileSelect={handleFileSelect}
            sx={{ display: 'none' }}
          />

          <Box
            sx={{
              height: { xs: 450, sm: 500, md: 600 },
              width: '100%',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: { xs: 0, sm: 1 },
              border: { xs: 1, sm: 0 },
              borderColor: 'divider',
            }}
          >
            <DataGrid
              rows={documents}
              columns={columns}
              density={isMobile ? 'compact' : 'comfortable'}
              disableColumnFilter
              disableColumnMenu
              disableEval
              loading={isLoading}
              slots={{
                noRowsOverlay: VaultNoRowsOverlay,
              }}
              slotProps={{
                loadingOverlay: {
                  variant: 'skeleton',
                  noRowsVariant: 'skeleton',
                },
              }}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              disableRowSelectionOnClick
              autoPageSize
              pageSizeOptions={[10, 25, 50]}
              sx={{
                height: '100%',
                border: 'none',
                '& .MuiDataGrid-main': {
                  width: '100%',
                },
                '& .MuiDataGrid-cell': {
                  display: 'flex',
                  alignItems: 'center',
                  py: { xs: 1.5, sm: 2 },
                  px: { xs: 1, sm: 2 },
                  fontSize: { xs: '0.875rem', sm: 'inherit' },
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  opacity: 1,
                  touchAction: 'manipulation',
                },
                '& .MuiDataGrid-columnHeader': {
                  px: { xs: 1, sm: 2 },
                  py: { xs: 1, sm: 1.5 },
                  backgroundColor: 'background.paper',
                  borderBottom: 1,
                  borderColor: 'divider',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: { xs: '0.875rem', sm: 'inherit' },
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                  },
                },
                '& .MuiDataGrid-row': {
                  transition: 'background-color 0.2s ease',
                  '&:nth-of-type(even)': {
                    backgroundColor: 'action.hover',
                  },
                  '&:hover': {
                    backgroundColor: 'action.selected',
                  },
                  '&:active': {
                    backgroundColor: 'action.disabledBackground',
                  },
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 1,
                  borderColor: 'divider',
                  justifyContent: 'flex-end',
                  px: { xs: 1, sm: 2 },
                },
                '& .MuiTablePagination-root': {
                  fontSize: { xs: '0.75rem', sm: 'inherit' },
                },
              }}
            />
          </Box>

          {isTagModalOpen && selectedDocument && (
            <VaultDocumentTagModal
              document={selectedDocument}
              onClose={handleTagModalClose}
              onSuccess={handleTagModalSuccess}
            />
          )}

          {isDeleteModalOpen && documentToDelete && (
            <DocumentDeleteModal
              document={documentToDelete}
              onClose={handleDeleteModalClose}
              onDocumentDeleted={handleDocumentDeleted}
            />
          )}

          {previewDocumentId && (
            <DocumentViewModal id={previewDocumentId} onClose={handleClosePreview} />
          )}
        </Stack>
      </DocumentStatusProvider>
    </MuiPageWrapper>
  );
};

export default VaultPage;
