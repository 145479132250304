import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { SetRentDetailsDto } from '@/types/dto/rent-unit.dto';

import { useGetRentDetails } from '@/api/endpoints/rent/useGetRentDetails';
import { useSetRentDetails } from '@/api/endpoints/rent/useSetRentDetails';

import FormTextField from '@/components/form/FormTextField';

interface RentDetailsFormValues {
  rentAmount: number;
  rentCurrency: string;
  rentInterval: 'month' | 'year';
}

interface RentDetailsFormSectionProps {
  unitId: number;
  onRentUpdate: (isNowDefined: boolean) => void;
}

const RentDetailsFormSection: React.FC<RentDetailsFormSectionProps> = ({
  unitId,
  onRentUpdate,
}) => {
  const { data: currentRentDetails, isLoading: isLoadingRent } = useGetRentDetails(
    unitId,
    !!unitId
  );

  const rentDetailsForm = useForm<RentDetailsFormValues>({
    defaultValues: {
      rentAmount: 0,
      rentCurrency: 'usd',
      rentInterval: 'month',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (currentRentDetails) {
      const validInterval =
        currentRentDetails.interval === 'month' || currentRentDetails.interval === 'year'
          ? currentRentDetails.interval
          : 'month';

      rentDetailsForm.reset({
        rentAmount: currentRentDetails.amount,
        rentCurrency: currentRentDetails.currency,
        rentInterval: validInterval,
      });
    }
  }, [currentRentDetails, rentDetailsForm]);

  useEffect(() => {
    rentDetailsForm.register('rentAmount', {
      required: 'Rent amount is required',
      min: {
        value: 1,
        message: 'Rent must be greater than 0',
      },
    });
  }, [rentDetailsForm]);

  const setRentDetailsMutation = useSetRentDetails(unitId, {
    onSuccess: () => {
      onRentUpdate(true);
    },
  });

  const handleSetRent = rentDetailsForm.handleSubmit(data => {
    const rentData: SetRentDetailsDto = {
      amount: data.rentAmount * 100, // Convert back to cents
      currency: data.rentCurrency,
      interval: data.rentInterval,
    };
    setRentDetailsMutation.mutate(rentData);
  });

  return (
    <Card>
      <CardHeader title={currentRentDetails ? 'Update Monthly Rent' : 'Define Monthly Rent'} />
      <CardContent>
        {isLoadingRent ? (
          <Stack alignItems="center" justifyContent="center" minHeight="100px">
            <CircularProgress />
          </Stack>
        ) : (
          <Box component="form" onSubmit={handleSetRent} noValidate sx={{ mt: 1 }}>
            <Stack spacing={2} direction="row">
              <FormTextField
                name="rentAmount"
                label="Rent Amount"
                control={rentDetailsForm.control}
                required
                helperText={rentDetailsForm.formState.errors.rentAmount?.message}
                error={!!rentDetailsForm.formState.errors.rentAmount}
                textFieldProps={{
                  type: 'number',
                  InputProps: {
                    startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                  },
                  sx: { width: '150px' },
                }}
              />
              {/* TODO: Add Currency and Interval Selectors - Using read-only values for now */}
              <FormTextField
                name="rentCurrency"
                label="Currency"
                control={rentDetailsForm.control}
                disabled
                textFieldProps={{ sx: { width: '100px' } }}
              />
              <FormTextField
                name="rentInterval"
                label="Interval"
                control={rentDetailsForm.control}
                disabled
                textFieldProps={{ sx: { width: '120px' } }}
              />
            </Stack>
            <Button
              type="submit"
              variant="contained"
              disabled={
                setRentDetailsMutation.isPending || !!rentDetailsForm.formState.errors.rentAmount
              }
              sx={{ mt: 3, mb: 2 }}
            >
              {setRentDetailsMutation.isPending ? <CircularProgress size={24} /> : 'Set Rent'}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default RentDetailsFormSection;
