import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { GenerateRentLinkDto } from '@/types/dto/rent-unit.dto';

import { useSendPaymentLinkEmail } from '@/api/endpoints/payment/useSendPaymentLinkEmail';
import { useGenerateRentLink } from '@/api/endpoints/rent/useGenerateRentLink';
import { useGetStripeAccountStatus } from '@/api/endpoints/stripeConnect/useGetStripeAccountStatus';
import { useGetAllTenants } from '@/api/endpoints/tenant/useGetAllTenants';
import { useUpdateTenantMetadata } from '@/api/endpoints/tenant/useUpdateTenantMetadata';
import { TenantModelWithContacts } from '@/api/models/tenant';
import { PMPRoutes } from '@/config/routes';

import FormTextField from '@/components/form/FormTextField';

interface GenerateLinkFormValues {
  tenantEmail: string;
  tenantName: string;
}

interface GenerateSubscriptionLinkSectionProps {
  unitId: number;
  isRentDefined: boolean;
}

interface ApiErrorResponse {
  statusCode: number;
  timestamp: string;
  path: string;
  name: string;
  message: string;
  details: Record<string, unknown>;
}

const GenerateSubscriptionLinkSection: React.FC<GenerateSubscriptionLinkSectionProps> = ({
  unitId,
  isRentDefined,
}) => {
  const history = useHistory();

  const { data: stripeAccountStatus, isLoading: isLoadingStatus } = useGetStripeAccountStatus(
    !!unitId
  );

  const generateLinkForm = useForm<GenerateLinkFormValues>({
    defaultValues: {
      tenantEmail: '',
      tenantName: '',
    },
    mode: 'onChange',
  });

  // State for storing the primary tenant
  const [primaryTenant, setPrimaryTenant] = useState<TenantModelWithContacts | null>(null);

  // Set up validation schema
  React.useEffect(() => {
    generateLinkForm.register('tenantEmail', {
      required: 'Email is required',
      pattern: {
        value: /^\S+@\S+\.\S+$/,
        message: 'Invalid email format',
      },
    });

    generateLinkForm.register('tenantName', {
      required: 'Full name is required',
    });
  }, [generateLinkForm]);

  // Fetch tenants for this unit
  const tenantsQuery = useGetAllTenants(
    {
      unit_id: unitId,
    },
    !!unitId
  );

  // Find the primary tenant and populate form
  useEffect(() => {
    if (tenantsQuery.data && tenantsQuery.data.length > 0) {
      // Find primary tenant
      const foundPrimaryTenant = tenantsQuery.data.find(
        tenant => tenant.metadata?.is_primary === true
      );

      if (foundPrimaryTenant) {
        // Store the primary tenant
        setPrimaryTenant(foundPrimaryTenant);

        // Find first email in contacts
        const primaryEmail =
          foundPrimaryTenant.contacts.find(contact => contact.email)?.email || '';

        // Set form values
        generateLinkForm.setValue('tenantName', foundPrimaryTenant.full_name);
        generateLinkForm.setValue('tenantEmail', primaryEmail);
      }
    }
  }, [tenantsQuery.data, generateLinkForm]);

  // State for displaying generated link
  const [generatedLink, setGeneratedLink] = React.useState<string | null>(
    primaryTenant?.metadata?.payment_link || null
  );

  // Update generatedLink when primaryTenant changes
  useEffect(() => {
    if (primaryTenant?.metadata?.payment_link) {
      setGeneratedLink(primaryTenant.metadata.payment_link);
    }
  }, [primaryTenant]);

  // State for storing error message
  const [errorMessage, setErrorMessage] = React.useState<string>(
    'Failed to generate link. Please try again.'
  );

  // Mutation for updating tenant metadata
  const updateTenantMetadataMutation = useUpdateTenantMetadata(primaryTenant?.id || 0, {
    onError: () => {
      enqueueSnackbar('Failed to save payment link to tenant record', { variant: 'error' });
    },
  });

  // Mutation for sending payment link email
  const sendPaymentLinkEmailMutation = useSendPaymentLinkEmail({
    onError: () => {
      enqueueSnackbar('Failed to send payment link email', { variant: 'error' });
    },
  });

  // State for tracking email sent
  const [isEmailSending, setIsEmailSending] = useState(false);

  // State for confirmation dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingFormData, setPendingFormData] = useState<GenerateLinkFormValues | null>(null);

  // State for regeneration confirmation
  const [confirmRegenerateDialogOpen, setConfirmRegenerateDialogOpen] = useState(false);

  // Handler for sending payment link email
  const handleSendPaymentLinkEmail = async () => {
    if (!primaryTenant || !generatedLink) return;

    const tenantEmail = primaryTenant.contacts.find(contact => contact.email)?.email || '';
    if (!tenantEmail) {
      enqueueSnackbar('Tenant has no email address to send to', { variant: 'error' });
      return;
    }

    try {
      setIsEmailSending(true);

      // Call the API endpoint to send the payment link email
      await sendPaymentLinkEmailMutation.mutateAsync({
        tenantId: primaryTenant.id,
        paymentLink: generatedLink,
        tenantEmail,
        tenantName: primaryTenant.full_name,
      });

      // Update tenant metadata with email sent timestamp
      if (primaryTenant) {
        const currentMetadata = primaryTenant.metadata || {};
        updateTenantMetadataMutation.mutate({
          metadata: {
            ...currentMetadata,
            payment_link_email_sent: true,
            payment_link_email_sent_at: new Date().toISOString(),
          },
        });
      }

      enqueueSnackbar('Payment link email sent successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to send payment link email', { variant: 'error' });
    } finally {
      setIsEmailSending(false);
    }
  };

  // Mutation for generating link
  const generateRentLinkMutation = useGenerateRentLink(unitId, {
    onSuccess: data => {
      const paymentLink = data.checkoutUrl;
      setGeneratedLink(paymentLink);

      // Save the link to the tenant's metadata if we have a primary tenant
      if (primaryTenant) {
        const currentMetadata = primaryTenant.metadata || {};
        updateTenantMetadataMutation.mutate({
          metadata: {
            ...currentMetadata,
            payment_link: paymentLink,
            // Reset email sent status when generating new link
            payment_link_email_sent: false,
            payment_link_email_sent_at: null,
          },
        });
      }
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as ApiErrorResponse | undefined;
      if (errorData?.message) {
        setErrorMessage(errorData.message);
      } else {
        setErrorMessage('Failed to generate link. Please try again.');
      }
    },
  });

  const handleGenerateLink = generateLinkForm.handleSubmit(data => {
    // Store form data and open confirmation dialog
    setPendingFormData(data);
    setConfirmDialogOpen(true);
  });

  // Handler for confirming link generation
  const handleConfirmGeneration = () => {
    if (!pendingFormData) return;

    const linkData: GenerateRentLinkDto = {
      tenantEmail: pendingFormData.tenantEmail,
      tenantName: pendingFormData.tenantName,
    };
    generateRentLinkMutation.mutate(linkData);
    setConfirmDialogOpen(false);
  };

  // Handler for canceling link generation
  const handleCancelGeneration = () => {
    setConfirmDialogOpen(false);
    setPendingFormData(null);
  };

  // Handler for regenerating link
  const handleRegenerateLink = () => {
    setConfirmRegenerateDialogOpen(true);
  };

  // Handler for confirming link regeneration
  const handleConfirmRegeneration = () => {
    setGeneratedLink(null);
    setConfirmRegenerateDialogOpen(false);

    // If primary tenant exists, reset metadata
    if (primaryTenant) {
      const currentMetadata = primaryTenant.metadata || {};
      updateTenantMetadataMutation.mutate({
        metadata: {
          ...currentMetadata,
          payment_link: undefined,
          payment_link_email_sent: false,
          payment_link_email_sent_at: null,
        },
      });
    }
  };

  // Handler for canceling link regeneration
  const handleCancelRegeneration = () => {
    setConfirmRegenerateDialogOpen(false);
  };

  // Check if email has been sent
  const emailHasBeenSent = primaryTenant?.metadata?.payment_link_email_sent === true;
  const emailSentTimestamp = primaryTenant?.metadata?.payment_link_email_sent_at;

  // Format the date for display
  const formattedEmailSentDate = emailSentTimestamp
    ? new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(new Date(emailSentTimestamp))
    : null;

  const isLoading = isLoadingStatus || tenantsQuery.isLoading;
  const rentAmountSet = isRentDefined;
  const isOnboardingComplete = stripeAccountStatus?.onboardingComplete ?? false;

  const navigateToPayouts = () => {
    history.push(PMPRoutes.accountPayouts);
  };

  // Common description text to avoid duplication
  const descriptionText = (
    <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
      Generate a payment link to begin rent collection. When ready, the tenant will be sent an email
      with this payment link where they can set up a recurring payment for the rent amount you have
      specified.
    </Typography>
  );

  // Determine content based on loading state, rent amount, and onboarding status
  const renderContent = () => {
    if (isLoading) {
      return (
        <Stack alignItems="center" justifyContent="center" minHeight="150px">
          <CircularProgress />
        </Stack>
      );
    }

    if (!rentAmountSet) {
      return (
        <Alert severity="info">
          Please define a monthly rent amount in the section above to enable payment link
          generation.
        </Alert>
      );
    }

    if (!isOnboardingComplete) {
      return (
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={navigateToPayouts}>
              Go to Payouts Setup
            </Button>
          }
        >
          You need to complete your Stripe account setup before you can generate payment links.
        </Alert>
      );
    }

    return (
      <>
        {descriptionText}

        {/* Show either the existing link or the link generation form */}
        {generatedLink ? (
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                wordBreak: 'break-all',
                mb: 3,
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Tenant Payment Link:
              </Typography>
              <Typography
                component="a"
                href={generatedLink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ overflowWrap: 'break-word' }}
              >
                {generatedLink}
              </Typography>
            </Box>

            {emailHasBeenSent && formattedEmailSentDate && (
              <Box sx={{ mb: 3, p: 2, bgcolor: 'success.lighter', borderRadius: 1 }}>
                <Typography variant="body2" color="success.dark">
                  Email sent to tenant on {formattedEmailSentDate}
                </Typography>
              </Box>
            )}

            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={handleRegenerateLink}
                disabled={updateTenantMetadataMutation.isPending}
              >
                Generate New Link
              </Button>

              <Button
                variant="contained"
                onClick={handleSendPaymentLinkEmail}
                disabled={
                  !primaryTenant ||
                  !primaryTenant.contacts.some(c => c.email) ||
                  !generatedLink ||
                  isEmailSending
                }
              >
                {isEmailSending && <CircularProgress size={24} />}
                {!isEmailSending && emailHasBeenSent && 'Resend Rent Payment Link Email'}
                {!isEmailSending && !emailHasBeenSent && 'Send Rent Payment Link Email to Tenant'}
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleGenerateLink} noValidate sx={{ mt: 1 }}>
            <Stack spacing={2}>
              <FormTextField
                name="tenantEmail"
                label="Tenant Email Address"
                control={generateLinkForm.control}
                required
                helperText={generateLinkForm.formState.errors.tenantEmail?.message}
                error={!!generateLinkForm.formState.errors.tenantEmail}
                textFieldProps={{
                  fullWidth: true,
                  autoComplete: 'email',
                }}
              />
              <FormTextField
                name="tenantName"
                label="Tenant Full Name"
                control={generateLinkForm.control}
                required
                helperText={generateLinkForm.formState.errors.tenantName?.message}
                error={!!generateLinkForm.formState.errors.tenantName}
                textFieldProps={{
                  fullWidth: true,
                  autoComplete: 'name',
                }}
              />
            </Stack>
            <Button
              type="submit"
              variant="contained"
              disabled={
                generateRentLinkMutation.isPending ||
                !!generateLinkForm.formState.errors.tenantEmail ||
                !!generateLinkForm.formState.errors.tenantName
              }
              sx={{ mt: 3, mb: 2 }}
            >
              {generateRentLinkMutation.isPending ? (
                <CircularProgress size={24} />
              ) : (
                'Generate Link'
              )}
            </Button>
            {generateRentLinkMutation.isError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <Card>
      <CardHeader title="Generate Tenant Subscription Link" />
      <CardContent>
        {renderContent()}

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmDialogOpen}
          onClose={handleCancelGeneration}
          aria-labelledby="confirm-link-generation-dialog-title"
          aria-describedby="confirm-link-generation-dialog-description"
        >
          <DialogTitle id="confirm-link-generation-dialog-title">
            Confirm Generate Payment Link
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-link-generation-dialog-description">
              Are you sure you want to generate a payment link for {pendingFormData?.tenantName}?
              This link will allow the tenant to set up a recurring payment for the rent amount
              specified.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelGeneration}>Cancel</Button>
            <Button onClick={handleConfirmGeneration} variant="contained" autoFocus>
              Generate
            </Button>
          </DialogActions>
        </Dialog>

        {/* Regeneration Confirmation Dialog */}
        <Dialog
          open={confirmRegenerateDialogOpen}
          onClose={handleCancelRegeneration}
          aria-labelledby="confirm-link-regeneration-dialog-title"
          aria-describedby="confirm-link-regeneration-dialog-description"
        >
          <DialogTitle id="confirm-link-regeneration-dialog-title">
            Confirm Generate New Payment Link
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-link-regeneration-dialog-description">
              Are you sure you want to generate a new payment link? The current link will be
              invalidated, and any tenant who has this link will no longer be able to use it.
              {emailHasBeenSent && " You'll need to send the new link to the tenant again."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelRegeneration}>Cancel</Button>
            <Button onClick={handleConfirmRegeneration} variant="contained" autoFocus>
              Generate New Link
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default GenerateSubscriptionLinkSection;
