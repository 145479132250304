import {
  Button,
  Link as MuiLink,
  Stack,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { environment } from '@env';

import { GeocodeAPIV6Response } from '@/types/mapbox';

import DashboardHeader from '@/@mui/dashboard/DashboardHeader';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { useGetAllBuildings } from '@/api/endpoints/building/useGetAllBuildings';
import {
  ApiResponse_GetAll_PropertyLocation,
  useGetAllPropertyLocations,
} from '@/api/endpoints/propertyLocation/useGetAllPropertyLocations';
import { useGetAllUnits } from '@/api/endpoints/unit/useGetAllUnits';
import { PropertyLocationDeclaredUnitsCount } from '@/api/enums';
import { PropertyLocationModel } from '@/api/models/propertyLocation';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatPhoneNumber } from '@/utils/phone';
import {
  getPropertyTypeDisplayText,
  isSingleFamilyEquivalentType,
} from '@/utils/propertyLocationTypeUtils';
import { getResolvedRoutePath } from '@/utils/router';

import RowActionsMenu from '@/components/common/RowActionsMenu';
import PropertyLocationCreateModal from '@/components/propertyLocation/PropertyLocationCreateModal';
import PropertyLocationUpdateModal from '@/components/propertyLocation/PropertyLocationUpdateModal';

const PropertyLocationListPage: React.FC = () => {
  const { session } = useAuth();
  const { isSingleFamilyAccount } = usePropertyManager();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [propertyLocationToUpdate, setPropertyLocationToUpdate] =
    useState<PropertyLocationModel | null>(null);

  const onSortModelChange = useCallback(
    (_sortModel: GridSortModel) => setSortModel(_sortModel),
    []
  );

  const getAllPropertyLocationsQuery = useGetAllPropertyLocations();

  const getAllBuildingsQuery = useGetAllBuildings(
    {
      location_id: getAllPropertyLocationsQuery.data?.[0].id,
    },
    isSingleFamilyAccount && getAllPropertyLocationsQuery.data?.length === 1
  );

  const getAllUnitsQuery = useGetAllUnits(
    {
      buildingId: getAllBuildingsQuery.data?.[0].id,
    },
    isSingleFamilyAccount && getAllBuildingsQuery.data?.length === 1
  );

  const geocodeLocationsQuery = useQuery({
    enabled: !!getAllPropertyLocationsQuery.data?.length,
    queryKey: ['GET', 'geocode', getAllPropertyLocationsQuery.data?.map(loc => loc.address_text)],
    queryFn: async () => {
      if (!getAllPropertyLocationsQuery.data) {
        return [];
      }

      const geocodedLocations = await Promise.all(
        getAllPropertyLocationsQuery.data.map(async location => {
          try {
            const response = await axios.get<GeocodeAPIV6Response>(
              'https://api.mapbox.com/search/geocode/v6/forward?',
              {
                params: new URLSearchParams({
                  q: location.address_text,
                  access_token: environment.mapboxApiKey,
                  autocomplete: 'false',
                  country: 'US',
                  format: 'geojson',
                  language: 'en-US',
                  limit: '1',
                  types: 'address',
                }),
              }
            );
            return {
              id: location.id,
              coordinates: response.data.features[0]?.geometry?.coordinates as
                | [number, number]
                | null,
            };
          } catch (error) {
            return {
              id: location.id,
              coordinates: null,
            };
          }
        })
      );
      return geocodedLocations;
    },
  });

  const [downloadProgress, setDownloadProgress] = useState<number>(0);

  const downloadDocumentsMutation = useMutation({
    mutationFn: async (propertyLocation: PropertyLocationModel) => {
      setDownloadProgress(0);

      const response = await axios.get(
        `${environment.api}/document/location/${propertyLocation.id}/download`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
          responseType: 'blob',
          timeout: 300000, // 5 minutes timeout
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          onDownloadProgress: progressEvent => {
            if (progressEvent.total) {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setDownloadProgress(progress);
            } else {
              // If total is unknown, show indeterminate progress
              setDownloadProgress(-1);
            }
          },
        }
      );

      if (!response.data || response.data.size === 0) {
        throw new Error('Empty response received');
      }

      if (!(response.data instanceof Blob)) {
        throw new Error('Response is not a blob');
      }

      const contentType = response.headers['content-type'];
      if (!contentType || !contentType.includes('zip')) {
        throw new Error(`Invalid content type received: ${contentType}`);
      }

      const blob = new Blob([response.data], { type: contentType });

      // Get filename from content-disposition header or generate default
      const contentDisposition = response.headers['content-disposition'] || '';
      const filenameMatch = contentDisposition.match(/filename="(.+?)"/);
      const filename = filenameMatch
        ? filenameMatch[1]
        : `location_${propertyLocation.id}_documents.zip`;

      // Create download link and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    onSuccess: () => {
      setDownloadProgress(0);
      enqueueSnackbar('Documents downloaded successfully.', { variant: 'success' });
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError) {
        if (error.code === 'ECONNABORTED') {
          enqueueSnackbar('Download timed out. Please try again.', { variant: 'error' });
        } else if (error.response?.status === 404) {
          enqueueSnackbar('No documents found for this location.', { variant: 'warning' });
        } else if (error.response?.status === 403) {
          enqueueSnackbar('You do not have permission to download these documents.', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(`Failed to download location documents: ${error.message}`, {
            variant: 'error',
          });
        }
      } else if (error instanceof Error) {
        enqueueSnackbar(`Download failed: ${error.message}`, { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to download location documents.', { variant: 'error' });
      }
      setDownloadProgress(0);
    },
  });

  const onDownloadDocuments = useCallback(
    (propertyLocation: PropertyLocationModel) =>
      downloadDocumentsMutation.mutateAsync(propertyLocation),
    [downloadDocumentsMutation]
  );

  // const locationCoordinates = useMemo(() => {
  //   if (!geocodeLocationsQuery.data) return [];
  //   return geocodeLocationsQuery.data;
  // }, [geocodeLocationsQuery.data]);

  const isLoading =
    getAllPropertyLocationsQuery.isLoading ||
    getAllBuildingsQuery.isLoading ||
    getAllUnitsQuery.isLoading ||
    geocodeLocationsQuery.isLoading;

  const renderLink = useCallback(
    (params: GridRenderCellParams<ApiResponse_GetAll_PropertyLocation[0]>) => {
      const locationId = Number(params.id);
      const linkPath = getResolvedRoutePath(PMPRoutes.propertyLocationView, {
        id: String(locationId),
      });

      return (
        <MuiLink component={RouterLink} to={linkPath}>
          {params.value}
        </MuiLink>
      );
    },
    []
  );

  const PropertyLocationListNoRowsOverlay = useCallback(
    () => <NoRowsOverlay entityName="Location" />,
    []
  );

  // Define responsive columns based on screen size
  const columns: GridColDef<ApiResponse_GetAll_PropertyLocation[0]>[] = useMemo(() => {
    const baseColumns: GridColDef<ApiResponse_GetAll_PropertyLocation[0]>[] = [
      {
        field: 'name',
        headerName: 'Property Name',
        flex: 1,
        minWidth: 150,
        renderCell: renderLink,
      },
      {
        field: 'email',
        headerName: 'Property Email',
        flex: 1,
        valueFormatter: (email: ApiResponse_GetAll_PropertyLocation[0]['email']) =>
          email?.value || 'Contact Terr.ai',
      },
      {
        field: 'phone',
        headerName: 'Property Phone',
        flex: 1,
        valueFormatter: (phone: ApiResponse_GetAll_PropertyLocation[0]['phone']) =>
          phone?.value ? formatPhoneNumber(phone.value) : 'Contact Terr.ai',
      },
      {
        field: 'actions',
        headerName: '',
        align: 'right',
        width: 50,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_PropertyLocation[0]>) => (
          <RowActionsMenu
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  setPropertyLocationToUpdate(params.row);
                  setIsUpdateModalOpen(true);
                },
              },
              {
                label: 'Download Documents',
                onClick: () => onDownloadDocuments(params.row),
                loadingProgress: downloadProgress,
                isLoading:
                  downloadDocumentsMutation.isPending &&
                  downloadDocumentsMutation.variables?.id === params.row.id,
              },
            ]}
          />
        ),
      },
    ];

    // Add columns based on screen size
    if (!isMobile) {
      baseColumns.splice(2, 0, {
        field: 'type',
        headerName: 'Property Type',
        flex: 1,
        minWidth: 130,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_PropertyLocation[0]>) =>
          getPropertyTypeDisplayText(params.row.type),
      });
    }

    if (!isTablet) {
      baseColumns.splice(isMobile ? 2 : 3, 0, {
        field: 'units',
        headerName: 'Units',
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<ApiResponse_GetAll_PropertyLocation[0]>) => {
          if (isSingleFamilyEquivalentType(params.row.type)) {
            return '1';
          }
          const totalUnits =
            params.row.buildings?.reduce((acc, building) => acc + building._count.units, 0) || 0;
          const declaredRange = (() => {
            switch (params.row.declared_units_count) {
            case PropertyLocationDeclaredUnitsCount.FROM_1_TO_5:
              return '(1-5)';
            case PropertyLocationDeclaredUnitsCount.FROM_6_TO_25:
              return '(6-25)';
            case PropertyLocationDeclaredUnitsCount.FROM_26_TO_100:
              return '(26-100)';
            case PropertyLocationDeclaredUnitsCount.FROM_101_TO_500:
              return '(101-500)';
            case PropertyLocationDeclaredUnitsCount.ABOVE_500:
              return '(500+)';
            default:
              return '';
            }
          })();
          return `${totalUnits} ${declaredRange}`;
        },
      });

      // baseColumns.splice(isMobile ? 3 : 4, 0, {
      //   field: 'email',
      //   headerName: 'Property Email',
      //   flex: 1,
      //   minWidth: 180,
      //   valueFormatter: (email: ApiResponse_GetAll_PropertyLocation[0]['email']) =>
      //     email?.value || 'Contact Terr.ai',
      // });
    }

    return baseColumns;
  }, [
    isMobile,
    isTablet,
    downloadProgress,
    downloadDocumentsMutation,
    onDownloadDocuments,
    renderLink,
  ]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        flex: '1 1 100%',
        padding: t => ({
          xs: t.spacing(1),
          sm: t.spacing(2),
          md: t.spacing(3),
        }),
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: { xs: 2, sm: 0 },
          display: 'flex',
          justifyContent: { sm: 'space-between' },
          width: '100%',
        }}
      >
        <DashboardHeader hasInnerHtml title="My Properties: <em>Locations</em>" />
        <Button
          color="primary"
          variant="contained"
          size={isMobile ? 'small' : 'medium'}
          startIcon={<PlusIcon />}
          onClick={() => setIsCreateModalOpen(true)}
        >
          Add Location
        </Button>
      </Box>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          mb: 2,
          mt: { xs: 1, sm: 0 },
        }}
      >
        These are the properties you're currently managing on the platform. Whenever you have a new
        rental property, you can add it as a new location using the "Add Location" button.
      </Typography>

      {/* <Box sx={{ height: 400, width: '100%' }}>
        <MapboxMap locations={locationCoordinates} interactive />
      </Box> */}

      <Box
        sx={{
          width: '100%',
          height: { xs: 400, sm: 500, md: 600 },
          overflow: 'auto',
        }}
      >
        <DataGrid
          rows={getAllPropertyLocationsQuery.data ?? []}
          columns={columns}
          density="comfortable"
          disableColumnFilter
          disableColumnMenu
          disableEval
          loading={isLoading}
          slots={{
            noRowsOverlay: PropertyLocationListNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          disableRowSelectionOnClick
          hideFooter
          sx={{
            '& .MuiDataGrid-cell': {
              padding: t => ({
                xs: t.spacing(1),
                sm: t.spacing(1.5),
                md: t.spacing(2),
              }),
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: t => t.palette.background.paper,
            },
          }}
        />
      </Box>

      {isCreateModalOpen && (
        <PropertyLocationCreateModal
          onClose={() => setIsCreateModalOpen(false)}
          onPropertyLocationCreated={() => setIsCreateModalOpen(false)}
        />
      )}

      {isUpdateModalOpen && !!propertyLocationToUpdate && (
        <PropertyLocationUpdateModal
          propertyLocation={propertyLocationToUpdate}
          onClose={() => {
            setIsUpdateModalOpen(false);
            setPropertyLocationToUpdate(null);
          }}
        />
      )}
    </Stack>
  );
};

export default PropertyLocationListPage;
