import { Alert, CircularProgress, Link as MuiLink, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { ExtractParams } from '@/types/params';

import { useGetAllBuildings } from '@/api/endpoints/building/useGetAllBuildings';
import { useGetRentDetails } from '@/api/endpoints/rent/useGetRentDetails';
import { useGetStripeAccountStatus } from '@/api/endpoints/stripeConnect/useGetStripeAccountStatus';
import { useGetAllUnits } from '@/api/endpoints/unit/useGetAllUnits';
import { PMPRoutes } from '@/config/routes';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

import GenerateSubscriptionLinkSection from './GenerateSubscriptionLinkSection';
import RentDetailsFormSection from './RentDetailsFormSection';

const UnitViewRentSubpage: React.FC = () => {
  const { id: unitIdString } = useParams<ExtractParams<PMPRoutes.unitView>>();
  const { locationId: locationIdString } =
    useParams<ExtractParams<PMPRoutes.propertyLocationViewRent>>();
  const { isSingleFamilyLocation } = usePropertyManager();

  // For direct unit access
  const directUnitId = unitIdString ? Number(unitIdString) : undefined;
  const locationId = locationIdString ? Number(locationIdString) : undefined;

  // Initialize state to track the unit ID
  const [unitId, setUnitId] = useState<number | undefined>(directUnitId);

  // For single family homes, get the first unit ID if not provided
  const getAllBuildingsQuery = useGetAllBuildings(
    {
      location_id: locationId,
    },
    !!locationId && isSingleFamilyLocation && !directUnitId
  );

  const getAllUnitsQuery = useGetAllUnits(
    {
      buildingId: getAllBuildingsQuery.data?.[0]?.id,
    },
    !!getAllBuildingsQuery.data?.[0]?.id && isSingleFamilyLocation && !directUnitId
  );

  // Set the unit ID when we get it for single family homes
  useEffect(() => {
    if (isSingleFamilyLocation && !unitId && getAllUnitsQuery.data?.[0]?.id) {
      setUnitId(getAllUnitsQuery.data[0].id);
    }
  }, [isSingleFamilyLocation, unitId, getAllUnitsQuery.data]);

  const { data: initialRentDetails, isLoading: isLoadingInitialRent } = useGetRentDetails(
    unitId,
    !!unitId
  );

  const [isRentDefined, setIsRentDefined] = useState<boolean>(false);

  useEffect(() => {
    if (initialRentDetails) {
      setIsRentDefined(initialRentDetails.amount > 0);
    } else if (!isLoadingInitialRent) {
      setIsRentDefined(false);
    }
  }, [initialRentDetails, isLoadingInitialRent]);

  const handleRentUpdate = (isNowDefined: boolean) => {
    setIsRentDefined(isNowDefined);
  };

  const { data, isLoading: isLoadingStripeStatus } = useGetStripeAccountStatus();
  const { onboardingComplete } = data ?? {};

  const isLoading =
    (isSingleFamilyLocation && !unitId) || isLoadingInitialRent || isLoadingStripeStatus;

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (!unitId) {
    return (
      <Stack spacing={3}>
        <Typography>Loading unit information...</Typography>
      </Stack>
    );
  }

  // Render onboarding prompt or the main content
  return (
    <Stack spacing={3}>
      {!onboardingComplete ? (
        <Alert severity="warning">
          Please complete your Stripe account onboarding via the{' '}
          <MuiLink component={RouterLink} to={PMPRoutes.account}>
            Account Settings
          </MuiLink>{' '}
          page before defining rent or generating payment links.
        </Alert>
      ) : (
        <>
          <RentDetailsFormSection unitId={unitId} onRentUpdate={handleRentUpdate} />
          {isRentDefined && (
            <GenerateSubscriptionLinkSection unitId={unitId} isRentDefined={isRentDefined} />
          )}
        </>
      )}
    </Stack>
  );
};

export default UnitViewRentSubpage;
